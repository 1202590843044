<template>
  <s-dialog to="stds-dialog-overview" size="lg" :open="true">
    <s-dialog-overlay />
    <s-dialog-panel class="h-auto cursor-default">
      <st-dialog-header @clickClose="closeDialog">
        <span class="font-bold cursor-text">
          {{
            $t(
              'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_title'
            )
          }}
        </span>
      </st-dialog-header>
      <s-dialog-content>
        <s-dialog-content-body>
          <ul class="flex flex-col gap-20 cursor-text">
            <safe-html
              tag="li"
              class="list-decimal ml-20 text-lg leading-lg font-regular text-on-surface-elevation-2"
              :html="
                $t(
                  'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_content1'
                )
              "
            />
            <safe-html
              tag="li"
              class="list-decimal ml-20 text-lg leading-lg font-regular text-on-surface-elevation-2"
              :html="
                $t(
                  'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_content2'
                )
              "
            />
          </ul>
          <div
            ref="editableDivReason"
            class="mt-20 border-1 border-solid border-border py-12 px-16 rounded-xl bg-[var(--stds-glob-color-gray20)] text-md leading-sm font-regular before:text-placeholder before:text-md [&:empty:before]:content-[attr(placeholder)] focus-visible:outline-[#6892FF] cursor-text"
            :placeholder="
              $t(
                'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_reason_for_requst_place_holder'
              )
            "
            contenteditable="true"
            @input="handleEditableInputReason"
          ></div>
          <input-text
            name="manualSecurityReason"
            :placeholder="
              $t(
                'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_reason_for_requst_place_holder'
              )
            "
            :rules="{
              max_length: {
                length: 100,
                showError: false,
                message: $t('studio.common.def_key.exceed', { length: 100 })
              }
            }"
            :containerClass="'opacity-0 invisible absolute top-0 left-0'"
          />
          <st-error-message
            v-if="manualSecurityReasonError"
            :errorMessage="manualSecurityReasonError"
          />
          <div class="mt-[6rem] flex items-baseline gap-12">
            <div class="flex flex-col flex-grow">
              <input-text
                name="manualSecurityCompany"
                :placeholder="
                  $t(
                    'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_name_place_holder'
                  )
                "
                :clearable="false"
                :rules="{
                  max_length: {
                    length: 50,
                    showError: false,
                    message: $t('studio.common.def_key.exceed', { length: 50 })
                  },
                  required: {
                    value: true,
                    showError: false,
                    message: $t('studio.build.request_manual_test_pop.request_form_name_req_msg')
                  }
                }"
                :pattern="REGEX_MANUAL_SECURITY"
                @keydown="handleCompanyKeydown"
              />
              <st-error-message
                v-if="manualSecurityCompanyError"
                :errorMessage="manualSecurityCompanyError"
              />
            </div>
            <p class="text-lg font-bold leading-lg text-on-surface-elevation-2 shrink-0">
              {{
                $t(
                  'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_agreement_text'
                )
              }}
            </p>
          </div>
          <div class="mt-[6rem] flex flex-col pr-36">
            <div class="ml-auto">
              <div class="flex flex-row gap-12">
                <div class="flex items-center gap-8">
                  <div class="w-[8rem]">
                    <input-text
                      ref="yearRef"
                      name="manualSecurityYear"
                      :placeholder="'YYYY'"
                      maxLength="4"
                      :pattern="REGEX_YEAR"
                      :caretPosition="Position.RIGHT"
                      :countable="false"
                      :clearable="false"
                      :rules="{
                        required: {
                          value: true,
                          showError: false,
                          message: $t('studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_req_msg')
                        },
                        max_length: {
                          length: 4,
                          showError: false,
                          message: $t('studio.common.def_key.exceed', { length: 4 })
                        }
                      }"
                      :containerClass="yearError ? 'border-2 pr-[1.1rem] pl-[1.1rem] border-error' : ''"
                      @input="handleYearInput"
                      @keydown="(e) => handleNumberInputKeydown(e, 4)"
                    />
                  </div>
                  <span class="text-lg font-regular leading-lg text-on-surface-elevation-2">
                    {{
                      $t(
                        'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_year'
                      )
                    }}
                  </span>
                </div>
                <div class="flex items-center gap-8">
                  <div class="w-[6rem]">
                    <input-text
                      ref="monthRef"
                      name="manualSecurityMonth"
                      :placeholder="'MM'"
                      maxLength="2"
                      :pattern="REGEX_MONTH"
                      :caretPosition="Position.RIGHT"
                      :countable="false"
                      :clearable="false"
                      :rules="{
                        required: {
                          value: true,
                          showError: false,
                          message: $t('studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_req_msg')
                        },
                        max_length: {
                          length: 2,
                          showError: false,
                          message: $t('studio.common.def_key.exceed', { length: 2 })
                        }
                      }"
                      :containerClass="
                        monthError ? 'border-2 pr-[1.1rem] pl-[1.1rem] border-error' : ''
                      "
                      @input="handleMonthInput"
                      @keydown="(e) => handleNumberInputKeydown(e, 2)"
                    />
                  </div>
                  <span class="text-lg font-regular leading-lg text-on-surface-elevation-2">
                    {{
                      $t(
                        'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_month'
                      )
                    }}
                  </span>
                </div>
                <div class="flex items-center gap-8">
                  <div class="w-[6rem]">
                    <input-text
                      ref="dayRef"
                      name="manualSecurityDay"
                      :placeholder="'DD'"
                      maxLength="2"
                      :caretPosition="Position.RIGHT"
                      :countable="false"
                      :clearable="false"
                      :pattern="REGEX_DAY"
                      :rules="{
                        required: {
                          value: true,
                          showError: false,
                          message: $t('studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_req_msg')
                        },
                        max_length: {
                          length: 2,
                          showError: false,
                          message: $t('studio.common.def_key.exceed', { length: 2 })
                        }
                      }"
                      :containerClass="dayError ? 'border-2 pr-[1.1rem] pl-[1.1rem] border-error' : ''"
                      @input="handleDayInput"
                      @keydown="(e) => handleNumberInputKeydown(e, 2)"
                    />
                  </div>
                  <span class="text-lg font-regular leading-lg text-on-surface-elevation-2">
                    {{
                      $t(
                        'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_day'
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="mt-4">
                <st-error-message
                  v-if="yearError || monthError || dayError"
                  :errorMessage="yearError || monthError || dayError"
                />
              </div>
            </div>
          </div>

          <div class="mt-12 flex items-baseline justify-end gap-12">
            <span class="text-lg font-regular leading-lg text-on-surface-elevation-2">
              {{
                $t(
                  'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_applicant_name'
                )
              }}&nbsp;:
            </span>
            <div class="w-[51rem] relative">
              <input-text
                name="manualSecurityName"
                :placeholder="
                  $t(
                    'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_signature_applicant_place_holder'
                  )
                "
                :rules="{
                  max_length: {
                    length: 100,
                    showError: false,
                    message: $t('studio.common.def_key.exceed', { length: 100 })
                  },
                  required: {
                    value: true,
                    showError: false,
                    message: $t('studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_signature_applicant_req_msg')
                  }
                }"
                :containerClass="'opacity-0 invisible absolute top-0 left-0'"
              />
              <div
                ref="editableDivName"
                :class="[
                  'border-1 border-solid border-border py-12 px-16 rounded-xl bg-[var(--stds-glob-color-gray20)] text-md leading-sm font-regular before:text-placeholder before:text-md [&:empty:before]:content-[attr(placeholder)] focus-visible:outline-[#6892FF] cursor-text',
                  'border-1 border-solid border-border py-12 px-16 rounded-xl bg-[var(--stds-glob-color-gray20)] text-md leading-sm font-regular before:text-placeholder before:text-md [&:empty:before]:content-[attr(placeholder)] focus-visible:outline-[#6892FF]',
                  manualSecurityNameError ? 'border-2 pr-[1.1rem] pl-[1.1rem] border-error' : ''
                ]"
                :placeholder="
                  $t(
                    'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_signature_applicant_place_holder'
                  )
                "
                contenteditable="true"
                @input="handleEditableInputName"
                @keydown="handleKeydownName"
              ></div>
              <st-error-message
                v-if="manualSecurityNameError"
                :errorMessage="manualSecurityNameError"
              />
            </div>
            <span class="text-lg font-regular leading-lg text-on-surface-elevation-2">{{
              $t(
                'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_applicant_signature'
              )
            }}</span>
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer class="shrink-0">
        <s-button class="sm:min-w-264" :disabled="!meta.valid" @click="onSubmit">
          {{
            $t(
              'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_submit_btn'
            )
          }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="stds-dialog-overview" />
</template>

<script setup lang="ts">
import { useFieldError, useForm, useSetFieldValue } from 'vee-validate';
import { nextTick, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { createManualSecurityCheckRequestApi } from '@/apis/build.api';
import SafeHtml from '@/components/common/safe-html.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import StErrorMessage from '@/components/common/st-error-message.vue';
import InputText from '@/components/validation/input-text.vue';
import { showAlert } from '@/composables/useDialog';
import { Position } from '@/enums/common.enum';
import type {
  FormReUploadValues
} from '@/types/build/build.type';

const { handleSubmit, setValues, meta } = useForm({
  initialValues: {
    manualSecurityCompany: '',
    manualSecurityName: '',
    manualSecurityYear: '',
    manualSecurityMonth: '',
    manualSecurityDay: '',
    manualSecurityReason: ''
  }
});

const { t } = useI18n();

const yearRef = ref<InstanceType<typeof InputText> | null>(null);
const monthRef = ref<InstanceType<typeof InputText> | null>(null);
const dayRef = ref<InstanceType<typeof InputText> | null>(null);

const REGEX_MANUAL_SECURITY = /^[A-Za-z\p{L}0-9\s.:™®()'!?&_"~,-]+$/u;
const REGEX_YEAR = /^\d{1,4}$/;
const REGEX_MONTH = /^\d{1,2}$/;
const REGEX_DAY = /^\d{1,2}$/;
const REGEX_NAME_OR_REASON = /^[A-Za-z\p{L}0-9\s.:™®()'!?&_"~,-]+$/u;
const manualSecurityCompanyValue = ref('');

const editableDivName = ref<HTMLDivElement | null>(null);
const editableDivReason = ref<HTMLDivElement | null>(null);

const dayError = useFieldError('manualSecurityDay');
const monthError = useFieldError('manualSecurityMonth');
const yearError = useFieldError('manualSecurityYear');
const manualSecurityNameError = useFieldError('manualSecurityName');
const manualSecurityCompanyError = useFieldError('manualSecurityCompany');
const manualSecurityReasonError = useFieldError('manualSecurityReason');
const setManualSecurityNameValue = useSetFieldValue('manualSecurityName');
const setManualSecurityReasonValue = useSetFieldValue('manualSecurityReason');

const props = defineProps<{
  buildId: string;
  gameId: string;
  errorCodeSecurity: number | null | undefined;
  errorDetailSecurity: Record<string, string> | null | undefined;
}>();

const emit = defineEmits(['close', 'submitSuccess']);

const closeDialog = () => {
  emit('close');
};

watch([manualSecurityCompanyValue], ([newCompanyValue]: [string]) => {
  const trimmedCompanyValue = newCompanyValue.trim();

  if (newCompanyValue !== trimmedCompanyValue) {
    manualSecurityCompanyValue.value = trimmedCompanyValue;
  }

  setValues({
    manualSecurityCompany: trimmedCompanyValue
  });
});

const handleYearInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.value.length === 4) {
    nextTick(() => {
      monthRef.value?.focus();
    });
  }
};

const handleMonthInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.value.length === 2) {
    nextTick(() => {
      dayRef.value?.focus();
    });
  }
};

const handleDayInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.value.length === 2) {
    nextTick(() => {
      editableDivName.value?.focus();
    });
  }
};

const handleEditableInput = (event: Event, setValue: (value: string) => void) => {
  const target = event.target as HTMLDivElement;
  const trimmedValue = target.innerText.trim();

  setValue(trimmedValue);
};

const handleKeydown = (event: KeyboardEvent) => {
  const key = event.key;

  if (
    !REGEX_NAME_OR_REASON.test(key) &&
    key !== 'Backspace' &&
    key !== 'Delete' &&
    key !== 'ArrowLeft' &&
    key !== 'ArrowRight'
  ) {
    event.preventDefault();
  }
};

// Usage for Name
const handleEditableInputName = (event: Event) => {
  return handleEditableInput(event, setManualSecurityNameValue);
};
const handleKeydownName = handleKeydown;

// Usage for Reason
const handleEditableInputReason = (event: Event) => {
  return handleEditableInput(event, setManualSecurityReasonValue);
};

const convertToTimestamp = (year: string, month: string, day: string): number => {
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  return date.getTime();
};

const onSubmit = handleSubmit(async (formValues: FormReUploadValues) => {
  const agreeDate = convertToTimestamp(
    formValues.manualSecurityYear,
    formValues.manualSecurityMonth,
    formValues.manualSecurityDay
  );

  const errorDetail = props.errorDetailSecurity || {};

  const baseSubmissionData = {
    buildId: props.buildId,
    gameId: props.gameId,
    companyName: formValues.manualSecurityCompany,
    ceoName: formValues.manualSecurityName,
    errorCode: props.errorCodeSecurity || 0,
    agreeDate,
    requestReason: formValues.manualSecurityReason || ''
  };

  try {
    const result = await createManualSecurityCheckRequestApi({
      ...baseSubmissionData,
      errorDetail
    });

    if (result?.code === 0) {
      await showAlert({
        severity: 'info',
        content: [
          t(
            'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_submit_pop_title'
          ),
          t(
            'studio.prj_prod.this_prod.product_data.build.detph_request_manual_test_pop_request_form_submit_pop_msg2'
          )
        ],
        confirmLabel: t('studio.common.popup_case_cf_btn'),
        confirmClasses: '!max-w-full !w-full'
      });

      emit('submitSuccess');
      closeDialog();
    }
  } catch (error) {}
});

const handleNumberInputKeydown = (event: KeyboardEvent, maxLength: number) => {
  const input = event.target as HTMLInputElement;
  if (event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'Tab' ||
      event.ctrlKey ||
      event.metaKey) {
    return;
  }

  if (!/^\d$/.test(event.key)) {
    event.preventDefault();
    return;
  }

  if (input.value.length >= maxLength && input.selectionEnd === input.selectionStart) {
    event.preventDefault();
  }
};

const handleCompanyKeydown = (event: KeyboardEvent) => {
  const key = event.key;

  if (
    key === 'Backspace' ||
    key === 'Delete' ||
    key === 'ArrowLeft' ||
    key === 'ArrowRight' ||
    key === 'Tab' ||
    key === 'Enter' ||
    key === ' ' ||
    event.ctrlKey ||
    event.metaKey
  ) {
    return;
  }

  const REGEX_COMPANY_KEY = /^[A-Za-z\p{L}0-9 ]$/u;

  if (!REGEX_COMPANY_KEY.test(key)) {
    event.preventDefault();
  }
};
</script>

<style scoped>
:deep(.input-text) {
  cursor: text;
}
</style>
